body {
  font-family: "Poppins";
  background: #ececec;
}
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
.threedots:after {
  content: "\2807";
  font-size: 16px;
}

a{
  color: inherit !important;
  text-decoration: none;
}
.drpdwnitem {
  color: #616773;
  opacity: 0.9;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  font-style: normal;
}

.drpdwnitem:hover {
  color: #6300b0 !important;
}

hr {
  margin: 0;
}

.sidebar {
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  background: #ececec;
  height: 100vh;
}

.sidebar .ProfileContent img {
  object-fit: cover;
  border-radius: 50%;
}

.sidebar-user-box h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #2a2a2e;
}

.sidebar-user-box span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #2a2a2e;
}

.sideNav .menuContent ul {
  padding: 0;
  list-style: none;
}

.sideNav .menuContent li {
  list-style: none;
  margin: 4px 10px 0 10px;
  align-items: center;
  padding: 8px 25px 8px 50px;
  color: #616773;
}

.sideNav .menuContent li.active,
.sideNav .menuContent li:hover {
  background: #6414b5;
  color: #ffffff !important;
  border-radius: 25px;
}

.sideNav .menuContent .nav-items-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
}

.sideNav .menuContent .listItem:hover {
  color: #ffffff !important;
}

.sideNav .menuContent li .nav-items-link:hover {
  color: #ffffff !important;
}
.sideNav .menuContent li .nav-items-link .icon {
  width: 20px;
  height: 30px;
}

.sideNav .menuContent li .nav-items-link .item {
  padding: 5px 0 0 10px;
}

.dropMenu {
  color: #616773 !important;
  padding: 0 0 0 10px;
}

.dropMenu:hover {
  color: #6414b5 !important;
}

.activeDropClass {
  padding: 0 0 0 10px;
  color: #6414b5;
}
.activeDropClass:hover {
  color: #6414b5 !important;
}
/* ------------------header navbar ----------------------------------- */
.navbar {
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(264.71deg, #8f009b 18.58%, #420085 128.77%);
}

.navbar .navbar-brand {
  color: #fff;
  font-weight: 500;
  font-size: 22px;
}

/* --------------------------------------- */
.sb-example-3 .search__input {
  width: 100%;
  padding: 8px 20px;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: D7D7D7;
  background-image: url("../Images/searchIcon.png");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 50px;
  border: 1px solid #d7d7d7;
}

/* --------------------- task & projects --------------------------------- */

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: none;
}

.tableData .projectName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
}

.search__input_task {
  width: 100%;
  /* padding: 8px 20px; */
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #3f4254;
  background-image: url("../Images/searchIcon.png");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 50px;
  border: 1px solid #d7d7d7;
}

.projectCheckbox {
  border-radius: 4px;
  margin-right: 3px;
}

.projectCheckbox[type="checkbox"] {
  color: #6414b5;
}

/* -------------------- Company settingggg-------------------------- */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #6414b5 !important;
}

.MuiSlider-root,
.MuiButtonBase-root {
  color: #6414b5 !important;
}

/* ------------------------------- */

.taskProjectTableData {
  background: #fff;
  padding: 10px;
  border-radius: 10px 0 0 10px;
  margin-top: 10px;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.taskProjectTableData th {
  padding: 15px 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
}

.tableHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
  opacity: 0.9;
}

.tableData {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
}

.tableData .nameIcon {
  width: 30px;
  border: 1px solid;
  padding: 2px;
  height: 30px;
  margin-right: 5px;
  text-align: center;
  border-radius: 16px;
}

.tableData .name {
  font-size: 14px;
  font-weight: 500;
}

.tableData ul {
  margin: 0;
  padding: 0;
}

.tableData ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #616773;
  width: 0;
}

.tableData .screenshot {
  font-weight: 500;
  font-size: 14px;
}

.tableData .time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #2a2a2e;
}

.timePercentage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #616773;
}

/* -------------------------admin user --------------------- */

.status {
  border: 1px solid #f4effa;
  width: 250px;
  height: 40px;
  border-radius: 50px;
  background-color: white;
}

.status_active {
  border: 1px solid #f4effa;
  width: 100px;
  margin-top: 1%;
  height: 32px;
  border-radius: 50px;
  background-color: #f4effa;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.status_inactive {
  border: 1px solid white;
  /**/
  width: 100px;
  margin-top: 1%;
  height: 32px;
  border-radius: 50px;
  background-color: white;
  /* #F4EFFA*/
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.st {
  /* margin-left: 5em; */
  color: gray;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

/* -------------------AddUsers---------------- */

.addUsers {
  padding: 30px 70px;
  background: #fff;
  border-radius: 10px;
}

.backBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #616773;
}

/* -------------------------admin user --------------------- */
.status {
  border: 1px solid #f4effa;
  width: 250px;
  height: 40px;
  border-radius: 50px;
  background-color: white;
}

.status_active {
  border: 1px solid #f4effa;
  width: 100px;
  margin-top: 1%;
  height: 32px;
  border-radius: 50px;
  background-color: #f4effa;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.status_inactive {
  border: 1px solid white;
  /**/
  width: 100px;
  margin-top: 1%;
  height: 32px;
  border-radius: 50px;
  background-color: white;
  /* #F4EFFA*/
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.st {
  /* margin-left: 5em; */
  color: gray;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

/* ---------Login---------------- */
.Login {
  background-color: #1e1e1e;
  background-image: url("../Images/bgImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.Login .logo {
  background: radial-gradient(
    127.54% 83.68% at 50% 50%,
    #8f009b 0%,
    #420085 100%
  );
  padding: 10px;
}

.Login .form {
  background: #fff;
}

.Login .f-pass {
  text-align: end;
}

.Login .f-pass a {
  color: gray;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}

.Login .label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
}

.Login .btn input,
.buttonDefault {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  padding: 10px 75px;
  border-radius: 6px !important;
  background: #6414b5;
}

.btn:hover {
  color: #fff !important;
}

.Login .control-label .signup {
  color: #111;
  margin-left: 5px;
  text-decoration: none;
  border: 1px solid #111;
  border-radius: 4px;
  padding: 5px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  transition: 0.5s ease;
}

.Login .btn input:hover {
  color: #fff !important;
}

.Login .form-check a:hover {
  color: #6414b5;
}

.Login .control-label .signup:hover {
  color: #6414b5;
}

.Login .nav a {
  color: #6414b5;
  border: 1px solid #6414b5;
  padding: 5px 15px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
}

/* CheckBox Label  */
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #616773;
}

@media (max-width: 991px) {
  .f-pass {
    text-align: start !important;
  }
}

/* -----------error------------ */

.error {
  color: red;
  background-color: grey;
}

/* -----status toggel--------- */
/* -----status toggel--------- */
/* .wrapper {
  margin: 100px 0;
  background-color: #616773;
} */
.toggle_radio {
  position: relative;
  text-shadow: #6414b5 !important;
  background: #ffffff;
  height: 34px;
  margin: 4px auto;
  overflow: hidden;
  padding: 0 !important;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  height: 36px;
  width: 320px;
}

.toggle_radio > * {
  float: left;
}

.toggle_radio input[type="radio"] {
  display: none;
  /*position: fixed;*/
}

.toggle_radio label {
  font: 90%/1.618 "Source Sans Pro";
  color: #6414b5;
  z-index: 0;
  display: block;
  width: 100px;
  height: 20px;
  margin: 3px 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1;
  /*background: rgba(0,0,0,.1);*/
  text-align: center;
  /*margin: 0 2px;*/
  /*background: blue;*/
  /*make it blue*/
}

.toggle_option_slider {
  /*display: none;*/
  /*background: red;*/
  width: 100px;
  /* s */
  height: 24px;
  margin-top: 2px;
  position: absolute;
  top: 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#first_toggle:checked ~ .toggle_option_slider {
  /* background: rgba(255,255,255,.3); */
  background: #f4effa;
  left: 3px;
  opacity: 0.8;
}

#second_toggle:checked ~ .toggle_option_slider {
  /* background: rgba(255,255,255,.3); */
  background: #f4effa;
  left: 109px;
  opacity: 0.8;
  margin-top: 2px;
}

#third_toggle:checked ~ .toggle_option_slider {
  /* background: rgba(255,255,255,.3); */
  background: #f4effa;
  color: #6414b5;
  left: 215px;
  opacity: 0.8;
}

.toggle_radioStatus {
  position: relative;
  text-shadow: #6414b5 !important;
  background: #ffffff;
  height: 34px;
  margin: 4px auto;
  overflow: hidden;
  padding: 0 !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  height: 35px;
  width: 219px;
}

.toggle_radioStatus > * {
  float: left;
}

.toggle_radioStatus input[type="radio"] {
  display: none;
  /*position: fixed;*/
}

.toggle_radioStatus label {
  font: 90%/1.618 "Source Sans Pro";
  color: #6414b5;
  display: block;
  width: 100px;
  height: 20px;
  margin: 5px 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1;
  /*background: rgba(0,0,0,.1);*/
  text-align: center;
  /*margin: 0 2px;*/
  /*background: blue;*/
  /*make it blue*/
}

.settingsDrop {
  width: 65%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
  opacity: 0.9;
}

.upload-logo-import {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #9097a5;
}

.bdr {
  border: 1px dashed #6414b5;
}

.del_avtar {
  width: 16px;
  height: 20px;
  text-align: center;
  bottom: 87px;
  left: 67px;
  position: relative;
  background: #f3f3f3;
  border: 1px red;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.imgbtn {
  position: relative;
  left: 5.7rem;
  top: 1.7rem;
  height: 1.5rem;
  width: 25%;
}

.imgbtn .btn {
  padding: 0 !important;
}

.imgbtn .edit {
  color: white !important;
  background: #6414b5 !important;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  left: 4rem;
  top: 1.5rem;
  background: #f3f3f3;
  border-radius: 5px;
  /* border-radius: 10px;  */
  /* border: 1px dashed #6414B5; */
  overflow: hidden;
  height: 7rem;
  /* margin: 50px auto; */
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 0px;
  z-index: 1;
  top: 0px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  /* border-radius: 100%;
  background: #ffffff; */
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #ba00f3;
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 7rem;
  position: relative;
  background: #f3f3f3;
  border-radius: 5px;
  /*border: 6px solid #f8f8f8; */
  /* box-shadow: 0px 0px 1px 3px rgba(0, 0, 0, 0.1); */
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Reports CSS */
.cardHeader {
  background: "#fcfcfc" !important;
  font-family: "poppins";
  color: "#3f4254";
  font-weight: 600;
}

.loaderDiv {
  margin: 20% 35%;
  position: fixed;
}

.loaderDiv .loading {
  position: relative;
  top: 50px;
  right: 55px;
}

.lableRepo {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-right: 4px;
  color: #9097a5;
}

.borders {
  border-radius: 5px;
  font-size: 14px;
  /* width: 80%; */
  border: 1px solid #ced4da;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tableCell {
  background: white !important;
  padding: 1rem 1rem !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* text-align: center; */
  color: #2a2a2e;
  opacity: 0.9;
}

.cellInactive {
  background: rgb(219 219 219) !important;
  padding: 1rem 1rem !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* text-align: center; */
  color: #2a2a2e;
  opacity: 0.9;
}

.tableCellCenter {
  background: white !important;
  padding-top: 1.8rem !important;
}

.back-radius {
  border-radius: 0 10px 10px 0;
}

.front-radius {
  border-radius: 10px 0 0 10px;
}

.checkBox {
  margin-right: 0.5rem;
  margin-top: 0;
}

input[type="checkbox"]:focus {
  outline: 0 !important;
  --tw-ring-shadow: none !important;
}

.progress-circle {
  width: 50px;
  height: 50px;
  /* margin-left: 25%; */
}

.userRepoSumm {
  border: 1px solid #00000033;
  border-radius: 10px;
  text-align: center;
  padding: 1rem;
}

.fontSize {
  font-size: 20px;
  font-weight: 600;
}

.repoTab:hover {
  color: #6300b0;
}

.repo-Thead {
  color: #3f4254;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.cardHeader {
  background: #fcfcfc !important;
  font-family: poppins;
  color: #3f4254;
  font-weight: 600;
}

.profileImg {
  /* background-color: #6300B0; */
  color: beige;
  border-radius: 50%;
  width: 25px;
  height: 23.4px;
  font-size: 12px;
  margin-right: 10px;
  font-weight: 500;
  text-align: center;
}

/* .profileImg p {
  margin-top: 3px;
} */

.repoData {
  color: #2a2a2e;
  opacity: 0.9;
  font-family: poppins;
  font-weight: 400;
  font-size: 13px;
}

.repoCol-1 {
  color: #616773;
  font-size: 13px;
  padding-left: 3rem;
}

.exportBtn {
  border-radius: 5px;
  border-color: #8a899c;
  background: #8a899c;
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.addBtn {
  border-radius: 20px;
  color: white;
  border-color: #6300b0;
  background: #6300b0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.sortIcon {
  height: 15px;
  margin: 2px;
}

.css-yk16xz-control {
  min-height: 52px !important;
  border-color: #ced4da;
}

.css-1pahdxg-control {
  min-height: 52px !important;
  border-color: #c200f3 !important;
}

.css-b8ldur-Input input {
  box-shadow: none !important;
}

.disabledIcn {
  /* pointer-events: none; */
  opacity: 0.4;
  cursor: not-allowed;
}

/* pagination css start */
.page-item.active .page-link {
  background: #6414b5 !important;
  color: #fff !important;
  border-color: #6414b5 !important;
}

.page-link {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 32px;
  color: #111 !important;
  /* width: 32px; */
  margin-right: 5px;
}

.page-item.disabled .page-link {
  color: #fefeff !important;
  background-color: #a3a3a3 !important;
}

.page-link:focus {
  box-shadow: none !important;
}

/* pagination css end */

.csvEx:hover {
  color: #fff !important;
}

.lbltxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2e;
  margin-top: 10px;
}

.settingBox {
  background: #fff;
  margin-top: 20px;
  padding: 2% 5%;
  border-radius: 4px;
}

.formlabel {
  margin: 5px 0;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #2a2a2e !important;
}

.linkItem {
  padding: 10px 30px;
  background: #f9f9f9;
}

.settingsDropdown {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-right: 8px;
  color: #9097a5;
}

.lbl {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616773;
}

.contactMail,
.contactMail:hover {
  color: #6300b0;
}

.table {
  margin-bottom: 0 !important;
}

.dropdownItems button:hover {
  color: #6300b0 !important;
  background-color: none !important;
}

.dropdownItems button:focus {
  color: #6300b0 !important;
  background-color: none !important;
}

.dropdownItems span:hover {
  color: #6300b0 !important;
  background-color: none !important;
}

.dropdown-item:active {
  color: #6300b0 !important;
  background-color: transparent !important;
}

.show {
  background-color: none !important;
}

.actionBtn {
  background: none !important;
  background-color: none !important;
}

.actionBtn:hover {
  background: none !important;
  background-color: none !important;
}

.actionBtn:focus {
  background: none !important;
}

@media screen and (max-width: 1200px) {
  .tableHead {
    min-width: 200px;
  }
  .timLine {
    width: 25%;
  }
}
@media screen and (max-width: 1025px) {
  .leaveTable {
    min-width: 200px;
  }
  .tableHead {
    min-width: 200px;
  }
  .timLine {
    width: 25%;
  }
}

@media screen and (max-width: 425px) {
  .avatar-upload {
    left: 2rem;
    top: 1.5rem;
  }

  .del_avtar {
    bottom: 88px;
    left: 30px;
  }
}

/* Dashboard page Responsive */
@media screen and (max-width: 575px) {
  .dashboardTeamDrop {
    width: 100%;
    display: flex !important;
    justify-content: none !important;
    align-items: center;
  }

  .btn-div-width {
    margin-top: 10px;
  }
  .adminStatus {
    justify-content: space-around;
  }
}

@media screen and (max-width: 791px) {
  .tableHead {
    min-width: 200px;
  }

  .exportBtn,
  .btn-div-width {
    width: 100%;
  }
}

/* Screenshot Page Responsive */
@media screen and (max-width: 426px) {
  .borders {
    width: 270px;
  }

  .contentSection {
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .borders {
    width: 250px;
  }
  .timlinebtn {
    display: flex;
    justify-content: end !important;
  }
  .timesave {
    width: 40%;
  }
  .timecncl {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .navbarImg {
    margin: 0 !important;
  }
  .timLine {
    width: 25%;
  }
  .timlinebtn {
    display: flex;
    justify-content: end !important;
  }
  .timesave {
    width: 40%;
  }
  .timecncl {
    width: 20%;
  }
}

@media screen and (max-width: 372px) {
  .borders {
    width: 225px;
  }

  .forgotPass {
    width: 100%;
  }

  /* .Report .borders {
    width: 200px;
  } */
}

@media screen and (max-width: 371px) {
  .borders {
    width: 190px;
  }

  .navbar .navbar-brand {
    font-size: 16px;
  }

  /* .profile-dropdown img {
    width: 40px !important;
    height: 40px !important;
  } */
}

@media screen and (min-width: 1600px) {
  .dashboardTeamDrop {
    width: 20% !important;
  }
}

@media screen and (min-width: 1200px) {
  .respLableRepo {
    width: 58px !important;
  }

  .dashboardTeamDrop {
    width: 23%;
  }
}

@media screen and (max-width: 1200px) {
  .screenshotNav {
    flex: 0 0 auto;
    width: 100%;
  }

  .ScreenshotUserDetials {
    display: flex;
    align-items: center;
  }

  .ScreenshotCol {
    width: 100%;
  }

  .ScreenshotCol .row {
    justify-content: space-between;
  }

  .ScreenshotCol .col-lg-12 {
    width: auto;
  }

  .ScreenshotTimeInfo {
    display: flex;
  }

  .adminTable .tableHead {
    min-width: 200px;
  }
}

@media screen and (max-width: 561px) {
  .ScreenshotCol .row {
    justify-content: center;
    display: grid;
    margin-bottom: 10px;
  }

  .firstHead {
    min-width: 300px !important;
  }

  .respLableRepo {
    width: 58px;
  }
  .uploadAppHeader .navbar-brand {
    font-size: 15px !important;
  }
  #dropdownMenuLink img {
    width: 35px !important;
    height: 35px !important;
  }
}

/* Report Table Responsive */
@media screen and (max-width: 1211px) {
  .reports .tableHead {
    min-width: 150px;
  }
}

/* Projects Table Responsive */
@media screen and (max-width: 1050px) {
  .projects .tableHead {
    min-width: 150px;
  }
}

/* Leaves Table Responsive */
@media screen and (max-width: 1220px) {
  .leaves .tableHead {
    min-width: 200px;
  }
}

/* Admin Table Responsive */
@media screen and (max-width: 991px) {
  .adminStatus {
    display: flex;
    justify-content: none !important;
    align-items: center;
  }

  .borders {
    width: 80%;
  }
}

.ProfileIamge {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  transform: translate;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.reportList li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #616773;
  margin-left: 35px;
}

.reportList td {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #616773;
}
.react-calendar__navigation {
  margin-top: 4px;
  background: linear-gradient(264.71deg, #8f009b 18.58%, #420085 128.77%);
  border-radius: 28px;
  color: #ffffff;
}
.react-calendar__navigation:hover {
  margin-top: 4px;
  background: linear-gradient(264.71deg, #8f009b 18.58%, #420085 128.77%);
  color: #ffffff !important;
}

.react-calendar {
  font-family: "Poppins" !important;
  width: auto !important;
  border: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__tile--active {
  background: #d45cdf !important;
  color: white;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: #8f009b !important;
}

.react-calendar__tile--now {
  background: #420085 !important;
  color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: linear-gradient(
    264.71deg,
    #420085 18.58%,
    #8f009b 128.77%
  ) !important;
  border-radius: 28px;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
  color: white !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.5em !important;
  font-size: 14px !important;
  border-radius: 26px !important;
}

.react-calendar__navigation button:disabled {
  background: linear-gradient(
    264.71deg,
    #420085 18.58%,
    #8f009b 128.77%
  ) !important;
}

.switch {
  width: 100% !important;
}

span.switch-on.btn.btn-primary {
  background-color: #420085 !important;
}

span.switch-off.btn.btn-info {
  background-color: #8f009b !important;
  color: #ffffff;
}

.text {
  width: 90%;
  margin: 5%;
}

.react-calendar__tile {
  height: 40px !important;
}

.weekend {
  font-size: 6px !important;
  font-family: "Poppins-SemiBold" !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  border-radius: 28px !important;
}

.react-calendar__month-view__days__day--weekend {
  color: forestgreen !important;
  font-weight: bolder !important;
}

.btn-info {
  background: linear-gradient(
    264.71deg,
    #420085 18.58%,
    #8f009b 128.77%
  ) !important;
  border-color: #420085 !important;
}

.leave {
  font-size: 6px !important;
  font-family: "Poppins-SemiBold" !important;
  color: crimson;
}

.teamMonth {
  background: linear-gradient(
    264.71deg,
    #420085 18.58%,
    #8f009b 128.77%
  ) !important;
}

.tablebackground {
  background-color: whitesmoke !important;
}

.tablespace {
  margin-top: 2px !important;
}
.HueInput {
  border: 1px solid gray;
  border-radius: 4px;
  padding: 15px;
}
.HueInput .hue-picker {
  width: 100% !important;
  cursor: pointer;
}
.formErr {
  color: #e62c29;
  font-size: 13px;
}

.btn:focus {
  box-shadow: none !important;
}

.customer .team-select {
  border: 1px solid #ced4da;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

button {
  outline: 0;
  box-shadow: none;
}

.pricing .priceCard {
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  background-color: #ffffff;
}

.pricing .priceCard .priceHeader {
  background: #f6f1fb;
  border-radius: 8px;
  margin: 5px;
  padding: 20px;
}
.pricing .priceCard .priceHeader .priceHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a2a2e;
}

.priceAmount .amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 66px;
  color: #6414b5;
}

.amountLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #616773;
}

.priceDesc .priceDescList .listItems {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a2a2e;
}

.priceDesc {
  padding: 20px;
  margin: 10px;
  height: 300px;
  overflow-y: scroll;
}

.priceDescList {
  margin-bottom: 10px;
}

.priceDescList span:first-child {
  width: 20px;
  margin-right: 10px;
}
.buyBtn {
  padding: 10px 40px;
  background: #6414b5;
  border: 1px solid #5b16a8;
  border-radius: 28px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin: 25px 0;
  font-size: 16px;
  color: #ffffff;
}

a[type="button"] {
  outline: none;
  box-shadow: none;
}

/* -------Download Page------------- */
.downloadPage {
  margin: 30px 0 20px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.downloadPage .head {
  font-size: 30px;
}

.downloadPage .content {
  color: #aaa;
  font-size: 16px;
  padding: 0 !important;
}

.downloadPage .content span {
  color: #838080;
  font-weight: 500;
}

.downloadPage p {
  font-size: 16px;
  color: #aaa;
}

/* ---------Reports---------- */
.workday {
  font-weight: 600;
}

.css-1wa3eu0-placeholder {
  font-size: 14px;
}

.viewPernissions {
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  color: #aaa;
  margin: 10px 0 5px 25px;
}

.dropdown .dropdown-menu {
  transform: translate3d(-150px, -72.333px, 0px) !important;
  inset: auto !important;
}

.bann {
  cursor: no-drop;
}

a:hover {
  color: #6414b5 !important;
}

.table-responsive {
  font-family: "Poppins" !important;
  font-style: normal !important;
}

 .compName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
}

.table-responsive .otherItem {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2e;
  opacity: 0.9;
}

@media screen and (max-width: 771px) {
  .paginations{
    flex-direction: column;
  }
  .paginations div{
    margin-bottom: 10px;
  }
}

.uploadAppInputFile{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 865px) {
  .uploadAppInputFile{
    flex-direction: column !important;
    float: left;
  };
}

.hoverWhite:hover{
  color: #ffffff !important;
}

