.SubscriptionDetailsCards {
  margin: 20px 0;
}

.SubscriptionDetailsCards .Cards {
  padding: 2px 10px;
  background: #ffffff;
  border-radius: 10px;
}

.SubscriptionDetailsCards .Cards .subscriptionDetails .monthly {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: #616773;
}

.SubscriptionDetailsCards .Cards .border {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 3;
  color: #2a2a2e;
  background: #f2ebfb;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

/* ---------------------------------------------- */

.summaryDetails .compName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3f4254;
}

.summaryDetails .otherItem {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a2e;
  opacity: 0.9;
}

/* -----------SuperAdminSupscription css---------------- */
.subscriptionHeader {
  margin: 20px 0;
}

.subscriptionHeader .addSubscriptionbtn {
  color: #ffffff !important;
}

.pricing .EditbtnPricing {
  font-family: "Poppins";
  margin-left: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.EditPlanForm .lableRepo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #616773;
}
.EditPlanForm input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #b6b6b6;
}

.EditPlanForm input {
  border: 1px solid #ced4da;
}

.EditPlanForm select {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  height: 40px;
  color: #b6b6b6;
}

.tableHead button,
.priceHeader button {
  border: none !important;
}

.sb-example-3 .search-input-field {
  opacity: 0.5;
  border: 1px solid #b6b6b6 !important;
  border-radius: 5px !important;
  height: 46px !important;
  width: 100%;
  background-color: transparent !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 10px;
  color: #3f4254;
  background-image: url("../Images/searchIcon.png");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
}

.sb-example-3 .search-input-field:focus {
  outline: 1px solid #6414b5 !important;
}

.sb-example-3 .search-input-field:focus-visible {
  outline: 1px solid #6414b5 !important;
}

@media screen and (max-width: 771px) {
  .paginations {
    flex-direction: column;
  }
  .paginations div {
    margin-bottom: 10px;
  }
}

.css-u4tvz2-MuiFormLabel-root.Mui-focused {
  color: #616773 !important;
}

/* ---------------------------- */
.disPriceCard {
  border: 1px solid #cdcdcd;
  background-color: #cdcdcd;
  border-radius: 8px;
  cursor: no-drop;
}

.dispriceHeader {
  background: #b5b3b3;
  border-radius: 8px;
  margin: 5px;
}
.dispriceHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgb(50, 50, 50);
}
.disamount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 66px;
  color: rgb(50, 50, 50);
}

.OptionChange {
  background: #6414b5;
  text-align: center;
  width: 25px;
  height: 23px;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}

.OptionChange span {
  margin-left: 4px !important;
}

.OptionChange .dropLink:hover {
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
